<template>
  <div class="center-width">
      <a-form-model ref="ruleForm" :model="globalInfo" :rules="rules">
        <a-row type="flex" justify="space-around" align="middle">
          <a-col :span="22">
            <div>
              <br>
              <p class="secondTitle"><a-icon type="form" />选择类型</p>
              <br>
            </div>
          </a-col>
          <a-col :span="18">
            <a-form-model-item prop="applyName">
              <a-select placeholder="机构类型" >
                <a-select-option v-for="value in memoryInfo.orgType" :key="value.item_value"  :value="value.item_value">
                  {{value.item_name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="18">
            <a-form-model-item>
              <a-select placeholder="所在市" style="width: 350px">
                <a-select-option v-for="value in memoryInfo.city" :key="value.district_name"  :value="value.district_id">
                  {{value.district_name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="7" :offset="1">
            <a-form-model-item>
              <a-select placeholder="所在区" >
                <a-select-option v-for="value in memoryInfo.orgType" :key="value.item_value"  :value="value.item_value">
                  {{value.item_name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8"></a-col>
          <a-col :span="18">
            <a-radio-group v-model="value" button-style="solid">
              <a-radio-button value="a" >
                机构
              </a-radio-button>
              <a-radio-button value="b" style="margin-left: 50px">
                人员
              </a-radio-button>
            </a-radio-group>
          </a-col>
          <a-col :span="22">
            <div>
              <br>
              <p class="secondTitle"><a-icon type="form" />选择机构/人员</p>
              <br>
            </div>
          </a-col>
          <a-col :span="18">
              <div>
                <a-radio-group button-style="solid">
                  <a-radio-button value="a" v-for="value in peodata" :title="value.person_name" :key="value.person_id">
                    机构
                  </a-radio-button>
                  <a-radio-button value="b" style="margin-left: 50px">
                    人员
                  </a-radio-button>
                </a-radio-group>
              </div>
          </a-col>
          <a-col :span="22">
            <div>
              <br>
              <p class="secondTitle"><a-icon type="form" />填写评价或评分</p>
              <br>
            </div>
          </a-col>
          <a-col :span="18">
            <a-rate />
          </a-col>
          <a-col :span="18">
            <a-form-model-item>
              <br>
              <a-textarea
                placeholder="办事内容"
                allow-clear
                :auto-size="{ minRows: 3, maxRows: 5 }"
              >
              </a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-button type="danger" block>
              提交
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    <br>
  </div>
</template>

<script>
export default {
  name: 'Global',
  methods: {
    getArea () {
      this.$api.get('/api/area').then((res) => {
        this.memoryInfo.area = res.data.collection
      })
    }
  },
  mounted () {
    this.$api.get('/api/provider_type').then((res) => {
      this.memoryInfo.orgType = res.data.collection
    })
    this.$api.get('/api/city').then((res) => {
      this.memoryInfo.city = res.data.collection
    })
  },
  data () {
    return {
      commontInfo: {
        city: ''
      },
      memoryInfo: {
        orgType: [
        ],
        city: [
        ],
        area: [
        ]
      },
      globalInfo: {
      },
      rules: {
      },
      peodata: [
        { provider_name: '电声与管弦乐', person_id: '1', phone: '奇怪的人', person_name: '这么多年我一个人一直再走' }
      ]
    }
  }
}
</script>
